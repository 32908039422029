
import { Category } from '@/models';
import { ApiService, LoaderService } from '@/services';
import { onMounted, defineComponent, ref } from 'vue';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import FileUpload from 'primevue/fileupload';
import Button from 'primevue/button';
import { useRouter } from 'vue-router';
import { CategoriesRoute } from '@/router';
import { useToast } from 'primevue/usetoast';
import { useConfirm } from 'primevue/useconfirm';

export default defineComponent({
	components: {
		InputText,
		Textarea,
		Button,
		FileUpload
	},
	props: {
		categoryId: String
	},
	setup(props) {
		const router = useRouter();
		const category = ref<Category>(null);
		const loadToken = LoaderService.invoke();
		const customImageUrl = ref(false);
		const previewUrl = ref('');
		const toast = useToast();
		const confirm = useConfirm();

		onMounted(async () => {
			if (!props.categoryId) {
				category.value = {
					title: null,
					ordinal: 0,
					description: null,
					imageUrl: ''
				};
				LoaderService.clear(loadToken);

				return;
			}

			try {
				category.value = await ApiService.get(
					`/categories/${props.categoryId}`
				);

				reloadPreview();
			} catch (e) {
				toast.add({
					severity: 'error',
					summary: 'Error Occurred',
					detail: 'Unable to load category. Try again later.',
					life: 3000
				});
				router.push({ name: CategoriesRoute });
			} finally {
				LoaderService.clear(loadToken);
			}
		});

		const submit = async () => {
			const submitLoadToken = LoaderService.invoke();

			try {
				if (props.categoryId) {
					await ApiService.put(
						`/categories/${props.categoryId}`,
						category.value
					);
				} else {
					await ApiService.post(`/categories`, category.value);
				}
				toast.add({
					severity: 'success',
					summary: 'Success',
					detail: 'Category saved.',
					life: 3000
				});
				router.push({ name: CategoriesRoute });
			} catch (e) {
				toast.add({
					severity: 'error',
					summary: 'Error Occurred',
					detail: 'Unable to save category. Try again later.',
					life: 3000
				});
			} finally {
				LoaderService.clear(submitLoadToken);
			}
		};

		const reloadPreview = () => (previewUrl.value = category.value.imageUrl);

		const uploadImage = async evt => {
			const imageLoadToken = LoaderService.invoke();

			const file = evt.files[0];
			const formData = new FormData();
			formData.append('file', file);

			const url = await ApiService.post<string>('/media', formData, {
				'Content-Type': 'multipart/form-data'
			});

			category.value.imageUrl = url;
			reloadPreview();

			LoaderService.clear(imageLoadToken);
		};

		const deleteCategory = () => {
			confirm.require({
				header: 'Are you sure?',
				message:
					'Are you sure you want to delete this category? All of its scenarios will be deleted.',
				icon: 'pi pi-exclamation-triangle',
				accept: async () => {
					await performDelete();
				},
				acceptLabel: 'Delete',
				acceptClass: 'p-button-danger',
				rejectLabel: 'I change my mind'
			});
		};

		const performDelete = async () => {
			const deleteLoadToken = LoaderService.invoke();

			try {
				if (!props.categoryId) {
					return;
				}
				await ApiService.remove(
					`/categories/${props.categoryId}`,
					category.value
				);

				toast.add({
					severity: 'success',
					summary: 'Success',
					detail: 'Category deleted.',
					life: 3000
				});

				router.push({
					name: CategoriesRoute
				});
			} catch (e) {
				toast.add({
					severity: 'error',
					summary: 'Error Occurred',
					detail: 'Unable to delete category. Try again later.',
					life: 3000
				});
			} finally {
				LoaderService.clear(deleteLoadToken);
			}
		};

		return {
			category,
			customImageUrl,
			previewUrl,
			uploadImage,
			submit,
			deleteCategory,
			reloadPreview
		};
	}
});
